<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Pemeriksaan Lab</strong>
              </h5>
            </template>
            <!-- <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" @click="pindahInput()"
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row> -->
            <!-- <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row> -->

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                
                   <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>
                   

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail'"
                      @click="pindahEdit(item.item)"
                      size="sm"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    
  </div>
</template>

<script>


// import { useGetAxios } from "@/composables/useAxios.js";

export default {
  components: {

  },
  name: "nilai_rujukan_lab",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
          {
            key: "no",
            label: "No",
            class: "text-left",
          },
          {
            key: "tanggal",
            label: "Tanggal",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "penanggung_jawab",
            label: "Penanggung Jawab",
            sortable: true,
            sortDirection: "desc",
            class: "text-right",
          },

          {
            key: "dokter_perujuk",
            label: "Dokter Perujuk",
            sortable: true,
            sortDirection: "desc",
            class: "text-right",
          },

          {
            key: "pemeriksa",
            label: "Pemeriksa",
            sortable: true,
            sortDirection: "desc",
            class: "text-right",
          },

          {
            key: "no_rm",
            label: "No. eRM",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "nik",
            label: "NIK",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "nama_lengkap",
            label: "Nama Pasien",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "jenis_kelamin",
            label: "Jenis Kelamin",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "tempat_tgl_lahir",
            label: "Tempat, Tanggal Lahir",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          // {
          //   key: "umur",
          //   label: "Umur",
          //   sortable: true,
          //   sortDirection: "desc",
          //   class: "text-left",
          // },

          {
            key: "no_antrian",
            label: "No. Antrean",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          // {
          //   key: "puskesmas_asal",
          //   label: "Puskesmas Asal",
          //   sortable: true,
          //   sortDirection: "desc",
          //   class: "text-left",
          // },

          // {
          //   key: "rujukan_dari",
          //   label: "Rujukan Dari",
          //   sortable: true,
          //   sortDirection: "desc",
          //   class: "text-left",
          // },

          {
            key: "status",
            label: "Status Antrean",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "actions",
            label: "Aksi",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
      ],
      items: [
        { no: '-', tanggal: '-', penanggung_jawab: '-', dokter_perujuk : '-', pemeriksa :'-', no_rm :'-', nik :'-',nama_pasien :'-', ruangan_asal :'-', jenis_kelamin :'-', tempat_tgl_lahir :'-', umur :'-', no_antrian :'-', puskesmas_asal :'-', rujukan_dari :'-', status_antrian :'-' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,

      data_res: null,
      data_err: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map(el => el.key)
    },
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
  },
  activated(){
    this.totalRows = this.items.length;
    this.getDatas();
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  // watch: {
  //   tanggal_sesi(newVal, oldVal){
  //     if(newVal != oldVal){
  //       console.log(newVal, oldVal);
  //       this.getDatas()
  //     }
  //   }
  // },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    
    pindahEdit(x){
      console.log(x, 'ini x');
        let vm = this
        vm.$router.push({
            path: "/laboratorium/detail_pemeriksaan_lab",
            query: { id_kunjungan_lab : x.kunjungan_lab_id, id_rm : x.rm_id, id_kunjungan : x.kunjungan_id, id_antrian : x.antrian_id },
        });
    },
    async getDatas() {
      try {
        this.tableBusy = true;
        let list_pemeriksaan = await this.$axios.post("/antrian/antrian_lab", {
          halaman: 0,
          jumlah: 1000,
          tanggal_antrian: this.$moment(this.tanggal_sesi).format("YYYY-MM-DD")
        });
        console.log('list_pemeriksaan', list_pemeriksaan)
        let x = list_pemeriksaan.data;
        if (x.status === 200 && x.message === "sukses") {
          this.items = list_pemeriksaan.data.data;
          for (let i = 0; i < this.items.length; i++) {
            let x = this.items[i];
            x.no = i + 1;
            x.tanggal = this.$moment(x.tanggal_antrian).format("DD-MM-YYYY")
            if(x.nama_penanggung_jawab){
              x.penanggung_jawab = x.nama_penanggung_jawab
            }else{
              x.penanggung_jawab = "-"
            }
            if(x.nama_pemeriksa){
              x.pemeriksa = x.nama_pemeriksa
            }else{
              x.pemeriksa = "-"
            }
            if(x.status_antrian == 1){
              x.status = 'Belum Proses'
            }else if(x.status_antrian == 2){
              x.status = 'Sedang Proses'
            }else if(x.status_antrian == 9){
              x.status = 'Selesai'
            }
            x.no_antrian = `${x.initial}${x.nomor_antrian}`
            x.tempat_tgl_lahir = `${x.tempat_lahir}, ${this.$moment(x.tanggal_lahir).format("DD-MM-YYYY")}`
          }
          this.totalRows = this.items.length;
        } else {
          throw x;
        }
      } catch (err) {
        if (err.message) {
          this.triggerAlert({
            variant: "danger",
            msg: (err.message).toUpperCase(),
            showing: true,
          });
        } else {
          this.triggerAlert({
            variant: "danger",
            msg: (err).toUpperCase(),
            showing: true,
          });
        }
      } finally {
        this.tableBusy = false;
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
